<template>
	<transition name="modal-fade">
		<div class="modal-backdrop">
			<div class="modal">
				<button
					class="closeBtn"
					@click="close"
				></button>
				<p v-if="isWarnPopup">До времени доставки более {{ getTime }}. Вы уверены, что хотите изменить
					статус?</p>
				<p v-else>Вы уверены, что хотите вернуть заказ в предыдущий статус?</p>
				<div>
					<button
						class="greyButton"
						@click="close"
					>Отменить
					</button>
					<button
						class="greenButton"
						@click="toggleDoStep"
					>Подтвердить
					</button>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	props: ['isWarnPopup', 'time'],
	name: 'StepPopup',
	computed: {
		getTime()
		{
			let str = '';
			if (Math.floor(this.time / 60) > 0) str += Math.floor(this.time / 60) + 'ч';
			if (this.time % 60 > 0) str += this.time % 60 + 'м';
			return str;
		}
	},
	methods: {
		close()
		{
			this.$emit('close');
		},
		toggleDoStep()
		{
			this.$emit('toggleDoStep');
		}
	},
}
</script>


<style
	lang="scss"
	scoped
>
.modal
{
	background: #FFFFFF;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	border-radius: 10px;
	overflow-x: auto;
	padding: 32px;
	width: max-content;
	margin: auto;
}

p
{
	margin: 24px 17px;
	font-size: 20px;
	font-weight: 700;
	line-height: 24px;
	text-align: center;
	width: 310px;
}

button
{
	border: none;
	outline: none;
	height: 48px;
	width: 160px;
}

.closeBtn
{
	background-color: inherit;
	background-image: url('~@/assets/img/closeRound.svg');
	width: 40px;
	height: 40px;
	float: right;
	margin: -30px -24px 0 0;
}

.greenButton
{
	color: white;
	background-color: $greenBackground;
	border-radius: 10px;
	font-weight: 700;
	font-size: 14px;
	margin-left: 24px;
}

.greyButton
{
	color: $greyText;
	background-color: $greyBackground;
	border: 2px solid;
	border-color: $greyBorder;
	border-radius: 10px;
	font-weight: 700;
	font-size: 14px;
}

modal-fade-enter,
.modal-fade-leave-active
{
	opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active
{
	transition: opacity .5s ease
}
</style>