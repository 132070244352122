<template>
	<div v-if="orderId">
		<div class="order-number-block">
			<div class="order-number-text">Заказ #{{ orderId }}</div>
			<div
				class="order-number-text pickup"
				v-if="orderInfo.PROPERTIES?.PICKUP?.VALUE"
			>{{ orderInfo.PROPERTIES.PICKUP.VALUE }}
			</div>
			<div
				class="order-delivery-time"
				v-if="orderInfo.PROPERTIES?.TIME?.VALUE "
			>{{ orderInfo.PROPERTIES.TIME.VALUE }}
			</div>
			<div
				class="order-address-text"
				v-if="orderInfo.PROPERTIES?.ADDRESS?.VALUE "
			>{{ orderInfo.PROPERTIES.ADDRESS.VALUE }}
			</div>
			<div
				class="order-address-text"
				v-if="orderInfo.PROPERTIES?.FIO?.VALUE"
			>
				{{ orderInfo.PROPERTIES.FIO.VALUE }}
			</div>
			<div
				class="order-address-text"
				v-if="orderInfo.PROPERTIES?.PHONE?.VALUE"
			>{{ orderInfo.PROPERTIES.PHONE.VALUE }}
			</div>
		</div>
		<div class="order-info">
			<div v-if="orderInfo.USER_DESCRIPTION">
				<div class="order-info-header-text">Комментарий к заказу</div>
				<div class="order-info-comment-text">{{ orderInfo.USER_DESCRIPTION }}</div>
			</div>
			<div v-if="orderInfo.COMMENT_FOR_COURIER">
				<div class="order-info-header-text">Комментарий для курьера</div>
				<div class="order-info-comment-text">{{ orderInfo.COMMENT_FOR_COURIER }}</div>
			</div>
			<div v-if="orderInfo.FORMATED_LIST && orderInfo.FORMATED_LIST.length">
				<div
					class="order-items-block"
					v-for="(item, key) in orderInfo.FORMATED_LIST"
					:key="`${item.name}-${key}`"
				>
					<div
						class="order-info-header-text"
						v-if="item.isHeader"
					>{{ item.name }}
					</div>
					<span
						class="name-lanch-for"
						v-else-if="item.isPerson"
					>{{ item.name }}</span>
					<div
						class="order-items-section"
						v-else
					>
						<div
							class="item-row"
							v-if="item.name"
						>
							<div class="item-name">
								<span
									class="item-number"
									v-if="item.number"
								>{{ item.number }}</span>{{ item.name }}
							</div>
							<div
								class="item-count-circle"
								v-if="item.count"
							>
								<span class="item-count-value">{{ item.count }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="print-block">
				<button
					class="print-btn"
					@click="printOrderCheck"
					:disabled="printing"
				>
					<div class="print-btn-text">Чек</div>
				</button>
				<button
					class="print-btn"
					@click="printOrderBarcode"
					:disabled="printing"
				>
					<div class="print-btn-text">Этикетка</div>
				</button>
			</div>
		</div>
	</div>
	<div
		class="select-order-block"
		v-else
	>
		<div class="select-order-block-wrapper">
			<div class="vectors-group"></div>
			<div class="select-order-text">
				Выберите заказ, чтобы посмотреть список
			</div>
		</div>
	</div>

</template>

<script>
import axios from "axios";
import qs from "qs";

export default {
	data()
	{
		return {
			orderInfo: {},
			printing: false
		}
	},
	props: ['orderId'],
	watch: {
		'orderId'()
		{
			this.orderInfo = {};
			this.getOrderInfo();
		},
	},
	methods: {
		async getOrderInfo()
		{
			let orderId = parseInt(this.orderId);
			if (!(orderId > 0)) return;

			try
			{
				let result = await axios.post(
					'/lk/getOrderDetail/',
					qs.stringify({
						orderId: this.orderId
					})
				);
				if (!result.data.success) return
				this.orderInfo = result.data.orderData;
			} catch (e)
			{
				throw new Error(e);
			}
		},

		async print(url)
		{
			let orderId = parseInt(this.orderId);
			if (!(orderId > 0)) return;
			try
			{
				this.printing = true;
				await axios.post(url,
					qs.stringify({
						orderId: this.orderId
					})
				);
			} catch (e)
			{
				throw new Error(e);
			} finally
			{
				this.printing = false;
			}
		},
		printOrderCheck()
		{
			this.print('/lk/printOrderCheck/')
		},
		async printOrderBarcode()
		{
			this.print('/lk/printOrderBarcode/')
		}
	},
}
</script>

<style lang="scss">
.vectors-group
{
	margin: auto;
	width: 32px;
	height: 22px;
	background-image: url('~@/assets/img/vectors.svg');
}

.select-order-block
{
	padding: 24px;
	width: 100%;
	height: 100%;
	display: table;
}

.select-order-block-wrapper
{
	display: table-cell;
	vertical-align: middle;
}

.select-order-text
{
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #979797;
	margin-top: 20px;
}

.order-number-block
{
	width: 100%;
	height: fit-content;
	padding: 20px 24px 12px 24px;
	background: #ECF7ED;
	border: 1px solid #F0F0F0;
	box-sizing: border-box;
	border-radius: 16px 16px 0px 0px;
}

.order-number-text
{
	width: 216px;
	height: 24px;
	margin-bottom: 4px;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 24px;
	color: #3D4248;

	&.pickup
	{
		color: #40AE49;
		margin-bottom: 10px;
	}
}

.order-address-text
{
	margin-top: 3px;
	font-style: normal;
	font-size: 16px;
	line-height: 24px;
	color: #979797;
}

.order-delivery-time
{
	width: 74px;
	height: 14px;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 120%;
	color: #979797;
}

.order-info
{
	padding: 0px 12px;
	position: relative;
}

.order-info-header-text
{
	margin-top: 16px;
	margin-left: 12px;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	color: #3D4248;
}

.order-info-comment-text
{
	padding: 10px 12px 30px 12px;
	margin-top: 8px;
	background: #FFFFFF;
	border: 1px solid #E5E5E7;
	box-sizing: border-box;
	border-radius: 8px;
	color: #40AE49;
}

.print-block
{
	width: 100%;
	height: 40px;
	display: flex;
	text-align: center;
	margin: 30px 0px;
	justify-content: space-between;
}

.print-btn
{
	padding: 10px 0px;
	width: 49%;
	height: 40px;
	line-height: 10px;
	border: none;
	background: #66BE6D;
	border-radius: 8px;

}

.print-btn-text
{
	width: 64px;
	height: 20px;

	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	display: inline;
	color: #F8F8F8;

}

.item-row
{
	width: 100%;
	background: #FFFFFF;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 14px rgba(0, 0, 0, 0.08);
	border-radius: 8px;
	padding: 12px 16px 12px 12px;
	display: flex;
	justify-content: space-between;

	&:not(:first-child)
	{margin-top: 4px; }

	&:first-child
	{margin-top: 8px; }
}

.item-name
{
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #3D4248;
	width: 70%;
	padding-left: 38px;
	position: relative;
	margin: auto 0;
}

.item-number
{
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: -0.04em;
	color: #979797;
	position: absolute;
	left: 0;
	top: 50%;
	margin-top: -8px;
}

.item-count-circle
{
	width: 24px;
	height: 24px;
	background-image: url('~@/assets/img/circle.svg');
	text-align: center;
	float: right;
	margin: auto 0 auto 8px;
}

.item-count-value
{
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #FFFFFF;
}

.name-lanch-for
{
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 120%;
	margin-top: 15px;
	color: #979797;
	margin-left: 12px;
	display: inline-block;
}

button[disabled]
{
	background-color: #b6b7b8;
	border-color: #b6b7b8;
	cursor: default;
}
</style>
