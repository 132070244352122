<template>
	<div
		class="sort"
		@click="isShown = !isShown"
	>
		<img src="~@/assets/img/sort.svg"/>
		<button
			id="show-modal"
			class="sortType__button"
		>{{ label }}
		</button>
		<DropList
			class="drop_list"
			v-if="isShown"
			:data="options"
			@change="update($event)"
		/>
	</div>
</template>

<script>
import DropList from '@/components/block/Rest/DropList.vue';

export default {
	components: {DropList},
	data()
	{
		return {
			isShown: false,
			options: [{
				value: 0,
				label: 'В работе'
			}, {
				value: 1,
				label: 'Оплачен'
			}, {
				value: 2,
				label: 'Собирается'
			}, {
				value: 3,
				label: 'Упакован'
			}, {
				value: 4,
				label: 'У курьера'
			}, {
				value: 5,
				label: 'Доставлен'
			}, {
				value: 6,
				label: 'Все'
			}],
			label: 'В работе',
		}
	},
	methods: {
		update(data)
		{
			this.isShown = false;
			this.label = data.label;
			this.$emit('sort', data.value);
		}
	},
	mounted()
	{
		document.addEventListener('click', (e) =>
		{
			let parent = e.target.closest('.sortType__button')
			if (!parent)
			{
				this.isShown = false;
			}
		})
	}

}
</script>


<style
	lang="scss"
	scoped
>
div .sort
{
	padding: 12px;
	border-radius: 10px;
	border: 1px solid;
	border-color: $greyBorder;
	display: grid;
	align-items: center;
	grid-template-rows: 35px auto;
	grid-template-columns: max-content 1fr;
	grid-template-areas:
                "img button"
                "dropList dropList";
	position: relative;

}

button
{
	border: none;
	outline: none;
	background-color: inherit;
	color: $greyText;
	grid-area: button;
	text-align: left;
}

span
{
	text-align: center;
}

img
{
	margin: 5px 6px 5px 0;
	grid-area: img;
}

.drop_list
{
	grid-area: dropList;
	border: none;
	z-index: 1;
	color: $greyText;
	position: absolute;
	top: 25px;
	width: 100%;
}
</style>
