var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[_vm.$style.row, _vm.isCurrent?_vm.$style.current:'']},[_c('div',{class:_vm.$style.infoItems},[_c('div',{class:[_vm.$style.infoBlock, _vm.$style.infoBlockFirst]},[_c('span',{class:[_vm.$style.greenFullStatus,
            _vm.getCurrentColorByStatus == 'yellow' ? _vm.$style.yellowStatus : '',
            _vm.getCurrentColorByStatus == 'red' ? _vm.$style.redStatus : '',
            _vm.getCurrentColorByStatus == 'red' && _vm.blinkRedStatus ? _vm.$style.redFullStatus : '',
            _vm.getCurrentColorByStatus == 'grey' ? _vm.$style.greyStatus : '',
            ]},[(_vm.timeFraction > 0)?_c('svg',{attrs:{"width":"18","height":"18","viewBox":"0 0 18 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{class:[_vm.$style.runningCircle, _vm.$style.pathRemaining],style:({strokeDasharray: _vm.circleDasharray}),attrs:{"d":"\n                        M 8, 8\n                        m -7, 0\n                        a 8,8 0 1,0 16,0\n                        a 8,8 0 1,0 -16,0"}})]):_vm._e()]),_c('div',{class:_vm.$style.info},[_c('div',{class:_vm.$style.id},[_vm._v("Заказ #"+_vm._s(_vm.order.id))]),_c('div',{class:_vm.$style.time},[_vm._v(_vm._s(_vm.order.time))])]),_c('button',{class:[_vm.$style.buttonFixed, _vm.order.fixed ? _vm.$style.activeFixButton : _vm.$style.inactiveFixButton],on:{"click":_vm.toggleFixed}})])]),(_vm.$store.state.user.IS_RST_MANAGER || _vm.$store.state.user.IS_RST_OWNER)?_c('div',{class:_vm.$style.statusBlockSelect},[_c('OrderChoose',{attrs:{"currentStatus":_vm.order.statusName,"id":_vm.order.id,"leftTime":_vm.getLeftTime,"isCurrent":_vm.isCurrent},on:{"changePrevStatus":function($event){return _vm.$emit('directPrevStatus', {orderId: _vm.order.id, statusName: $event})},"changeNextStatus":function($event){return _vm.$emit('directNextStatus', {orderId: _vm.order.id, statusName: $event})}}})],1):_c('div',{class:[_vm.leftButtonActivity ? '' : _vm.$style.statusBlock1Sect, _vm.$style.statusBlock]},[(_vm.leftButtonActivity)?_c('button',{class:[_vm.$style.greyStatusButton,
            _vm.order.status== 'P'?_vm.$style.greenStatusButton:''],on:{"click":_vm.leftStatusButtonClick}},[_c('div',[_vm._v(_vm._s(_vm.leftButtonText))]),(_vm.order.status == 'P' && !_vm.order.pickup)?_c('div',{class:_vm.$style.leftTime},[_vm._v(_vm._s(_vm.getLeftTime)+" ")]):_vm._e()]):_vm._e(),_c('button',{class:[_vm.$style.greyStatusButton,
            _vm.order.status== 'I' || _vm.pickupStatusBtn ? _vm.$style.whiteStatusButton:'',
            _vm.order.status== 'K' || _vm.order.status== 'C' ? _vm.$style.lightGreenStatusButton:''
            ],attrs:{"disabled":_vm.rightButtonActivity},on:{"click":_vm.rightStatusButtonClick}},[_c('div',[_vm._v(_vm._s(_vm.rightButtonText))]),(_vm.leftButtonActivity && !_vm.rightButtonActivity && !_vm.order.pickup)?_c('div',{class:_vm.$style.leftTime},[_vm._v(_vm._s(_vm.getLeftTime)+" ")]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }