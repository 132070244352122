<template>
	<div :class="[$style.row, isCurrent?$style.current:'']">
		<div :class="$style.infoItems">
			<div :class="[$style.infoBlock, $style.infoBlockFirst]">

            <span
				:class="[$style.greenFullStatus,
            getCurrentColorByStatus == 'yellow' ? $style.yellowStatus : '',
            getCurrentColorByStatus == 'red' ? $style.redStatus : '',
            getCurrentColorByStatus == 'red' && blinkRedStatus ? $style.redFullStatus : '',
            getCurrentColorByStatus == 'grey' ? $style.greyStatus : '',
            ]"
			>
                <svg
					v-if="timeFraction > 0"
					width="18"
					height="18"
					viewBox="0 0 18 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
                <path
					:style="{strokeDasharray: circleDasharray}"
					:class="[$style.runningCircle, $style.pathRemaining]"
					d="
                        M 8, 8
                        m -7, 0
                        a 8,8 0 1,0 16,0
                        a 8,8 0 1,0 -16,0"
				/>
                </svg>
            </span>

				<div :class="$style.info">
					<div :class="$style.id">Заказ #{{ order.id }}</div>
					<div :class="$style.time">{{ order.time }}</div>
				</div>

				<button
					:class="[$style.buttonFixed, order.fixed ? $style.activeFixButton : $style.inactiveFixButton]"
					@click="toggleFixed"
				></button>

			</div>
		</div>

		<div
			v-if="$store.state.user.IS_RST_MANAGER || $store.state.user.IS_RST_OWNER"
			:class="$style.statusBlockSelect"
		>
			<OrderChoose
				:currentStatus="order.statusName"
				:id="order.id"
				:leftTime="getLeftTime"
				:isCurrent="isCurrent"
				@changePrevStatus="$emit('directPrevStatus', {orderId: order.id, statusName: $event})"
				@changeNextStatus="$emit('directNextStatus', {orderId: order.id, statusName: $event})"
			/>
		</div>
		<div
			v-else
			:class="[leftButtonActivity ? '' : $style.statusBlock1Sect, $style.statusBlock]"
		>

			<button
				:class="[$style.greyStatusButton,
            order.status== 'P'?$style.greenStatusButton:'']"
				@click="leftStatusButtonClick"
				v-if="leftButtonActivity"
			>
				<div>{{ leftButtonText }}</div>
				<div
					v-if="order.status == 'P' && !order.pickup"
					:class="$style.leftTime"
				>{{ getLeftTime }}
				</div>
			</button>

			<button
				:class="[$style.greyStatusButton,
            order.status== 'I' || pickupStatusBtn ? $style.whiteStatusButton:'',
            order.status== 'K' || order.status== 'C' ? $style.lightGreenStatusButton:''
            ]"
				:disabled="rightButtonActivity"
				@click="rightStatusButtonClick"
			>
				<div>{{ rightButtonText }}</div>
				<div
					v-if="leftButtonActivity && !rightButtonActivity && !order.pickup"
					:class="$style.leftTime"
				>{{ getLeftTime }}
				</div>
			</button>

		</div>
	</div>
</template>

<script>
import {DateTime} from 'luxon'
import OrderChoose from '@/components/block/Rest/OrderChoose.vue'

export default {
	props: ['order', 'isCurrent'],
	components: {OrderChoose},
	data()
	{
		return {
			isShown: false,
			leftTimeForStatus: 0,
			timeLimitForStatus: 0,
			blinkRedStatus: false,
			interval: null,
		}
	},
	computed: {
		getPhone()
		{
			return this.order.phone ? '+7' + this.order.phone : '';
		},
		circleDasharray()
		{
			return `${(this.timeFraction * 51).toFixed(0)}, 51`;
		},
		timeFraction()
		{
			if (this.timeLimitForStatus > 0)
			{
				if (this.timeLimitForStatus < this.leftTimeForStatus) return 1;
				return this.leftTimeForStatus / this.timeLimitForStatus;
			}
			return 0;
		},
		getCurrentColorByStatus()
		{
			if (!this.leftButtonActivity) return 'grey';
			if (this.order.pickup) return '';
			if (this.leftTimeForStatus < this.timeLimitForStatus / 4) return 'red';
			if (this.leftTimeForStatus < this.timeLimitForStatus / 2) return 'yellow';
			;
			return null;
		},
		getLeftTime()
		{
			let sign = this.leftTimeForStatus >= 0 ? '' : '-';
			let leftTime = this.leftTimeForStatus >= 0 ? this.leftTimeForStatus : -this.leftTimeForStatus;
			let sec = leftTime % 60 < 10 ? '0' + leftTime % 60 : leftTime % 60;
			let hours = Math.floor(leftTime / 3600) < 10 ? '0' + Math.floor(leftTime / 3600) : Math.floor(leftTime / 3600);
			let minutes = Math.floor((leftTime - hours * 3600) / 60) < 10 ? '0' + Math.floor((leftTime - hours * 3600) / 60) : Math.floor((leftTime - hours * 3600) / 60);
			return hours > 0 ? sign + hours + ':' + minutes + ':' + sec : sign + minutes + ':' + sec;
		},
		leftButtonText()
		{
			if (this.order.status == 'C') return 'Дособрать';
			return this.order.status == 'P' ? 'Принять' : 'Отменить';
		},
		rightButtonText()
		{
			if (this.order.status === "C" && this.order.pickup)
			{
				return 'Выдан'
			}
			switch (this.order.status)
			{
				case 'P':
					return 'Собрать';
				case 'I':
					return 'Собрали';
				case 'C':
					return 'Упакован';
				case 'K':
					return 'У курьера';
				case 'F':
					return 'Доставлен';
			}
		},
		pickupStatusBtn()
		{
			return this.order.status == 'C' && this.order.pickup;
		},
		leftButtonActivity()
		{
			let stats = ['P', 'I', 'C'];
			return stats.includes(this.order.status);
		},
		rightButtonActivity()
		{
			return this.order.status == 'I' || this.pickupStatusBtn ? false : true;
		}
	},
	methods: {
		toggleFixed()
		{
			if (this.isCurrent)
			{
				this.$emit('toggleFixed', this.order)
			}
		},
		leftStatusButtonClick()
		{
			if (this.isCurrent)
			{
				if (this.order.status == 'P')
				{
					if (this.leftTimeForStatus > this.order.popupWarnLimit * 60) this.$emit('toggleWarn', this.order.id);
					else this.$emit('toggleNextStatus', this.order.id);
				} else this.$emit('togglePrevStatus', this.order.id);
			}
		},
		rightStatusButtonClick()
		{
			if ((this.order.status == 'I' || this.pickupStatusBtn) && this.isCurrent)
			{
				if (this.leftTimeForStatus > this.order.popupWarnLimit * 60) this.$emit('toggleWarn', this.order.id);
				else this.$emit('toggleNextStatus', this.order.id);
			}
		},
		countDown()
		{
			this.leftTimeForStatus -= 1;
			if (this.leftTimeForStatus < 0) this.blinkRedStatus = !this.blinkRedStatus;
		}
	},
	mounted()
	{
		this.leftTimeForStatus = this.order.statusLeftTime
		this.timeLimitForStatus = this.order.statusTimeLimit * 60
		this.interval = setInterval(this.countDown, 1000);
	}
	,
	beforeDestroy()
	{
		clearInterval(this.interval);
	},
	watch: {
		order:
			{
				handler: function (newValue, oldValue)
				{
					if (newValue.status != oldValue.status)
					{
						this.leftTimeForStatus = this.order.statusLeftTime
						this.timeLimitForStatus = this.order.statusTimeLimit * 60
					}
				},
				deep: true
			},
	}
}
</script>
<style
	lang="scss"
	scoped
>
div .sort
{
	grid-template-columns: none;
	grid-template-areas: none;
	display: block;
	padding: 10px 5px;
	max-width: 230px;
	min-width: 230px;
}
</style>
<style
	lang="scss"
	module
>

.row
{
	height: 72px;
	border: 1px solid $greyBackground;
	border-radius: 8px;
	padding: 10px;
	cursor: pointer;
	box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.08);
	display: flex;
	justify-content: space-between;
	position: relative;

	@media (max-width: 1300px)
	{
		display: grid;
		min-width: 0;
		grid-template-columns: repeat(2, 1fr);
	}

	&.current
	{
		border: 2px solid $green;
	}

	.infoColumn
	{
		flex-direction: column;
		align-items: flex-start !important;
		justify-content: center !important;
	}

	.infoHeader
	{
		font-size: 12px;
		color: #979797;
		line-height: 1;
		display: block;

		@media (max-width: 1460px)
		{
			display: none;
		}
	}

	.tabletHidden
	{
		display: flex;
		@media (max-width: 1300px)
		{
			display: none !important;
		}
	}

	.infoItems
	{
		display: flex;
		justify-content: flex-start;
	}

	.infoFooter
	{
		font-size: 14px;
		font-weight: 700;
		color: #3D4248;
		line-height: 1;

		&:not(:first-child)
		{
			margin-top: 8px;
		}
	}

	.id
	{
		font-family: $mainFontMedium;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 120%;
	}

	.time
	{
		font-family: $mainFontMedium;
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: 14px;
		letter-spacing: 0em;
		text-align: left;
		color: $greyText;
		margin-top: 5px;
		white-space: nowrap;
	}

	.info
	{
		padding: 0 16px 0 12px;
		@media (max-width: 767px)
		{
			padding: 0 5px 0 5px;
		}
	}

	.statusBlock
	{
		display: flex;
		flex-grow: 1;
		align-items: center;
		border: 1px solid;
		border-color: $greyBorder;
		border-radius: 10px;
		background-color: $greyBackground;
		padding: 2px;
		height: 100%;
	}

	.statusBlock1Sect
	{
		display: grid;
		grid-template-columns: 1fr;

		.lightGreenStatusButton
		{
			box-shadow: none !important;
		}
	}

	.statusBlockSelect
	{
		position: relative;
		margin-left: 30px;

		& button
		{
			padding-right: 40px;
			width: 100%;
		}
	}

	.infoBlock
	{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin: 0 25px;
	}

	.greyStatusButton
	{
		color: $greyText;
		background-color: inherit;
		border-radius: 10px;
		font-weight: 700;
		font-size: 14px;
		height: 100%;
		flex-grow: 1;
	}

	.greenStatusButton
	{
		color: white;
		background-color: $greenBackground;
		border-radius: 10px;
		font-weight: 700;
		font-size: 14px;
		height: 100%;
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 10px rgba(0, 0, 0, 0.12);
	}

	.lightGreenStatusButton
	{
		color: $green;
		background-color: $lightGreenBackground;
		border-radius: 10px;
		font-weight: 700;
		font-size: 14px;
		height: 100%;
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 10px rgba(0, 0, 0, 0.12);
	}

	.redStatusButton
	{
		color: white;
		background-color: $red;
		border-radius: 10px;
		font-weight: 700;
		font-size: 14px;
		height: 100%;
	}

	.whiteStatusButton
	{
		color: $green;
		background-color: white;
		border: 1px solid;
		border-color: $greyBorder;
		border-radius: 10px;
		font-weight: 700;
		font-size: 14px;
		height: 100%;
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 10px rgba(0, 0, 0, 0.12);
	}

	.leftTime
	{
		font-weight: 500;
	}

	.inactiveFixButton
	{
		background-image: url('~@/assets/img/fixInactive.svg');
	}

	.activeFixButton
	{
		background-image: url('~@/assets/img/fixActive.svg');
	}

	.greenFullStatus
	{
		width: 18px;
		height: 18px;
		flex-shrink: 0;
		transition: 1s linear all;
		background-image: url('~@/assets/img/greenFullOrder.svg');

		.runningCircle
		{
			color: $green;
		}
	}


	.yellowStatus
	{
		background-image: url('~@/assets/img/yellowOrder.svg');

		.runningCircle
		{
			color: $yellow;
		}
	}

	.redStatus
	{
		background-image: url('~@/assets/img/redOrder.svg');

		.runningCircle
		{
			color: $red;
		}
	}

	.redFullStatus
	{
		background-image: url('~@/assets/img/redFullOrder.svg');
		transition: none;
	}

	.greyStatus
	{
		border: none !important;
		background-image: url('~@/assets/img/greyOrder.svg');
	}

	.pathRemaining
	{
		stroke-width: 2px;
		stroke-linecap: round;
		stroke-dasharray: inherit;
		transform: rotate(90deg);
		transform-origin: center;
		transition: 1s linear all;
		fill-rule: nonzero;
		stroke: currentColor;
	}

	.buttonFixed
	{
		background-color: inherit;
		width: 40px;
		height: 40px;
		flex-shrink: 0;
		margin: 6px 10px;
		@media (max-width: 767px)
		{
			margin: 2px;
		}
	}

	button
	{
		border: none;
		outline: none;
	}
}
</style>

