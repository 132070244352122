<template>
	<transition name="modal-fade">
		<div class="modal-backdrop">
			<div class="modal">
				<span
					v-for="el in data"
					@click="change(el.value, el.label)"
				>{{ el.label }}</span>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	props: ['data'],
	name: 'DropList',
	methods: {
		change(val, label)
		{
			this.$emit('change', {value: val, label: label});
		},
	},
}
</script>


<style
	lang="scss"
	scoped
>
span
{
	cursor: pointer;
	margin-bottom: 10px;

	&:hover
	{
		color: $green;
	}
}

.modal
{
	background: #FFFFFF;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	border-radius: 10px;
	overflow-x: auto;
	display: flex;
	flex-direction: column;
	padding: 10px;
}

modal-fade-enter,
.modal-fade-leave-active
{
	opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active
{
	transition: opacity .5s ease
}
</style>