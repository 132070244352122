<template>
	<div>
		<img src="~@/assets/img/search.svg"/>
		<input
			placeholder="Поиск"
			v-model="order"
			@input="searchEvent"
		/>
		<button @click="setOrderNull"></button>
	</div>
</template>

<script>
export default {
	data()
	{
		return {
			order: null,
		}
	},
	methods: {
		searchEvent()
		{
			this.$emit('search', this.order)
		},
		setOrderNull()
		{
			this.order = null;
			this.$emit('search', this.order);
		}
	}

}
</script>

<style
	lang="scss"
	scoped
>
div
{
	display: grid;
	grid-template-columns: max-content 1fr max-content;
	align-items: center;
	border: 1px solid;
	border-color: $greyBorder;
	padding: 15px;
	border-radius: 10px;
}

button
{
	border: none;
	background-color: inherit;
	background-image: url('~@/assets/img/close.svg');
	width: 20px;
	height: 20px;
	margin-bottom: 5px;
	outline: none;
}

input
{
	border: none;
	height: 100%;
	outline: none;
	min-width: 80px;
}

img
{
	margin-right: 10px;
}
</style>