<template>
	<div>
		<button
			@click="emit(-1)"
			class="left"
		></button>
		<span>{{ type }} {{ forCalendar ? 'на ' : predlog + date.toRelativeCalendar() }}
            <span
				class="date"
				v-if="forCalendar"
			>{{ onMonthYear }}</span>
            <span
				class="date"
				:class="{warning}"
				v-else
			>{{ date.toLocaleString() }}</span>
            <span
				class="img"
				v-if="warning"
			/>
        </span>
		<button @click="emit(1)"></button>
	</div>
</template>

<script>
import {DateTime} from 'luxon'

export default {
	props: ['type', 'forCalendar', 'date', 'warning'],
	data()
	{
		return {
			monthes: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
		}
	},
	computed: {
		predlog()
		{
			return this.date.month == DateTime.local().month ? 'на ' : '';
		},
		onMonthYear()
		{
			return this.monthes[this.date.month - 1] + ' ' + this.date.year;
		}
	},
	methods: {
		emit(dir)
		{
			let mod = this.forCalendar ? 'month' : 'day';
			this.$emit('change', this.date.plus({[mod]: dir}));
		},
	}

}
</script>

<style
	lang="scss"
	scoped
>
div
{
	border: 1px solid;
	border-color: $greyBorder;
	border-radius: 10px;
	background-color: $greyBackground;
	display: grid;
	grid-template-columns: max-content 1fr max-content;
	align-items: center;
	padding: 0 5px;
}

button
{
	border: none;
	background-color: inherit;
	background-image: url('~@/assets/img/arrow.svg');
	background-repeat: no-repeat;
	color: $green;
	min-width: 30px;
	min-height: 25px;
	background-position: center;
	outline: none;
	padding: 0;
}

.left
{
	transform: scale(-1, 1);
}

span
{
	text-align: center;
	font-size: 18px;
	font-weight: 700;
	@media (max-width: 790px)
	{
		font-size: 14px;
	}

	.date
	{
		color: $green;
	}
}

.date.warning
{
	color: #FD470E;
}

.img
{
	display: inline-block;
	mask-image: url('~@/assets/img/alert.svg');
	background-color: #FD470E;

	width: 23px;
	height: 22px;
	margin-left: 5px;
	vertical-align: -2px;
}
</style>