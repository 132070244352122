<template>
	<div
		class="sort"
		@click="isShown = !isShown"
		:ref="id"
	>
		<button class="sort__button">
			<div>{{ currentStatus }}</div>
			<div class="line"/>
			<div v-if="currentStatus !== 'Доставлен'">{{ leftTime }}</div>
			<div class="arrow"/>
		</button>
		<DropList
			v-if="isShown && isCurrent"
			class="drop_list"
			:data="options"
			@change="update"
		/>
	</div>
</template>

<script>
import DropList from '@/components/block/Rest/DropList.vue';

export default {
	props: ['currentStatus', 'leftTime', 'isCurrent', 'id'],
	components: {DropList},
	data()
	{
		return {
			isShown: false,
			options: [{
				label: 'Оплачен',
				value: 0,
			}, {
				label: 'Собирается',
				value: 0,
			}, {
				label: 'Упакован',
				value: 0,
			}, {
				label: 'У курьера',
				value: 0,
			}, {
				label: 'Доставлен',
				value: 0,
			}],
		}
	},
	methods: {
		update(data)
		{
			this.isShown = false
			let idCur = this.options.findIndex((el) =>
			{
				return el.label == this.currentStatus;
			})
			let idNew = this.options.findIndex((el) =>
			{
				return el.label == data.label;
			})
			if (idNew > idCur)
			{
				this.$emit('changeNextStatus', data.label);
			}
			if (idNew < idCur)
			{
				this.$emit('changePrevStatus', data.label);
			}
		}
	},
	mounted()
	{
		document.addEventListener('click', (e) =>
		{
			let parent = e.target.closest('.sort__button')
			if (!parent || this.isShown !== this.isCurrent)
			{
				this.isShown = false;
			}
		})
	}
}
</script>


<style
	lang="scss"
	scoped
>
.line
{
	height: 2px;
	background: $greenBackground;
	width: 8px;
	margin: 0 9px;
}

div .sort
{
	border-radius: 10px;
	border: 1px solid;
	border-color: $greyBorder;
	background-color: $lightGreenBackground;
	display: grid;
	padding: 5px;
	align-items: center;
	grid-template-rows: 35px auto;
	grid-template-columns: max-content 1fr;
	grid-template-areas:
                "button"
                "dropList";

	&:before
	{
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: -30px;
		background: url('~@/assets/img/arrowFull.svg');
		background-size: cover;
		height: 14px;
		width: 16px;
	}
}

button
{
	padding: 5px 14px;
	border: none;
	outline: none;
	background-color: inherit;
	color: $green;
	font-weight: bold;
	font-size: 14px;
	align-items: center;
	width: 207px;
	grid-area: button;
	text-align: left;
	display: flex;
	justify-content: flex-start;
	position: relative;

	& div
	{
		pointer-events: none;
	}
}

.arrow
{
	background: url('~@/assets/img/arrow.svg');
	position: absolute;
	right: 3px;
	height: 32px;
	width: 32px;
	background-size: cover;
	transform: rotate(90deg);
}

span
{
	text-align: center;
}

.drop_list
{
	grid-area: dropList;
	border: none;
	z-index: 1;
	right: 0;
	left: 0;
	margin-top: 12px;
	position: absolute;
	color: $greyText;

	& .modal
	{
		padding: 10px 15px;
	}
}
</style>
